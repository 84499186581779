export const listYourCarEn = {
  ALL_WHEEL_DRIVE: 'All Wheel Drive',
  ANDROID_AUTO: 'Android Auto',
  APPLE_CAR_PLAY: 'Apple Car Play',
  AUX_INPUT: 'Aux Input',
  BACKUP_CAMERA: 'Backup Camera',
  BIKE_RACK: 'Bike Rack',
  BLIND_SPOT_WARNING: 'Blind Spot Warning',
  BLUETOOTH: 'Bluetooth',
  CHILD_SEAT: 'Child Seat',
  CONVERTIBLE: 'Convertible',
  GPS: 'GPS',
  HEATED_SEATS: 'Heated Seats',
  KEYLESS_ENTRY: 'Keyless Entry',
  PET_FRIENDLY: 'Pet Friendly',
  SKI_RACK: 'Ski Rack',
  SNOW_TIRES_OR_CHAINS: 'Snow Tires Or Chains',
  SUNROOF: 'Sunroof',
  TOLL_PASS: 'Toll Pass',
  USB_CHARGER: 'USB Charger',
  USB_INPUT: 'USB Input',
  WHEELCHAIR_ACCESSIBLE: 'Wheelchair Accesible',
  'ListYourCarPage:Text:Steps': 'Step {step} of 10',
  'ListYourCarPage:Text:Title': 'List Your Car',
  'Onboarding*:Button:Next': 'Next Step',
  'OnboardingCarAvailabilityForm:Error:Submit': 'It looks like there was a problem submiting the car availability',
  'OnboardingCarAvailabilityForm:Field:MaximumTripDuration': 'Maximum Trip Duration',
  'OnboardingCarAvailabilityForm:Field:MaximumTripDuration:2W': '2 weeks (recommended)',
  'OnboardingCarAvailabilityForm:Field:MinimumTripDuration': 'Minimum Trip Duration',
  'OnboardingCarAvailabilityForm:Field:MinimumTripDuration:1D': '1 Day (recommended)',
  'OnboardingCarAvailabilityForm:Field:ShortNotice': 'Short Notice',
  'OnboardingCarAvailabilityForm:Field:ShortNotice:1D': '1 Day (recommended)',
  'OnboardingCarAvailabilityForm:Text:Description': 'Setting your car\'s availability preferences helps us manage reservations efficiently and ensures that your vehicle is available to users at times that best suit you',
  'OnboardingCarAvailabilityForm:Text:MaximumTripDuration': 'Indicates the time limit that the car can be rented for a trip',
  'OnboardingCarAvailabilityForm:Text:MinimumTripDuration': 'Indicates the minimum amount of time the car can be rented',
  'OnboardingCarAvailabilityForm:Text:ShortNotice': 'Indicate how much time you need to prepare the car before each new trip',
  'OnboardingCarAvailabilityForm:Text:Title': 'Set the Availability of your Car',
  'OnboardingCarDetailsForm:Error:Submit': 'It looks like there was a problem submiting the car details',
  'OnboardingCarDetailsForm:Field:Description': 'Description',
  'OnboardingCarDetailsForm:Field:LicensePlate': 'License Plate',
  'OnboardingCarDetailsForm:Field:LicensePlateState': 'License Plate State',
  'OnboardingCarDetailsForm:Field:Name': 'Name',
  'OnboardingCarDetailsForm:Note:CarDetails': 'The name and description of your vehicle is the first thing users will see. Make it as attractive as possible to capture their interest and improve your rental possibilities',
  'OnboardingCarDetailsForm:Note:CarFeatures': 'Select the features and amenities your vehicle has, such as GPS or heated seats. If it doesn\'t have one, you can safely omit it',
  'OnboardingCarDetailsForm:Note:CarPlates': 'Enter the license plate number and issue status of your vehicle. This data helps verify its authenticity and comply with the platform\'s legal requirements',
  'OnboardingCarDetailsForm:Text:Description': 'Providing the characteristics of your car allows users to better understand the vehicle they are going to rent, helping them make an informed decision and improving their experience on the platform',
  'OnboardingCarDetailsForm:Text:Title': 'Describe the Features of your Car',
  'OnboardingCarDocumentsForm:Error:Submit': 'It looks like there was a problem submiting the car drivers license',
  'OnboardingCarDocumentsForm:Error:ValidateCarDocuments': 'Upload From 1 to 8 files',
  'OnboardingCarDocumentsForm:Note:Documents': 'Upload images or PDF files of the registration card and driver\'s license registered with the car. Make sure they are correct, as they will be reviewed to meet platform requirements',
  'OnboardingCarDocumentsForm:Text:Description': 'To ensure security and compliance, we need to verify your vehicle\'s official documents before enabling it for rental on the platform',
  'OnboardingCarDocumentsForm:Text:UploadFileDrag': 'Drag and drop your files here',
  'OnboardingCarDocumentsForm:Text:UploadFileClick': 'Or click to browse your files',
  'OnboardingCarDocumentsForm:Text:Title': 'Upload your Vehicle Documents',
  'OnboardingCarGuidelinesForm:Field:Checkbox': 'Guidelines Agreement',
  'OnboardingCarGuidelinesForm:Field:CheckboxLabel': 'By checking the box you are agreeing that you know the guidelines you have to comply with the car',
  'OnboardingCarGuidelinesForm:Text:Guidelines': `
  <p>To maintain quality and trust in our platform, you must accept the following commitments before each rental:</p>
  <ul>
  <li>Car Cleaning: Make sure your car is clean both inside and out.</li>
  <li>Full Tank: Provide your car with a full tank of fuel.</li>
  <li>Regular Maintenance: Perform necessary maintenance to ensure your car is in good condition.</li>
  <li>Platform Exclusivity: Do not list your car on another rental platform.</li>
  </ul>
  <p>These measures ensure a positive experience for users and maintain high standards in our community.</p>
  `,
  'OnboardingCarGuidelinesForm:Text:Title': 'Car Guidelines',
  'OnboardingHostAgreementForm:Error:CreateAgreement': 'It seems that there has been a problem submiting your agreement',
  'OnboardingHostAgreementForm:Field:Checkbox': 'Host Agreement',
  'OnboardingHostAgreementForm:Field:CheckboxLabel': 'By checking the box you are agreeing that you know the terms and conditions you have to fulfill with to become a host',
  'OnboardingHostAgreementForm:Text:Description': 'Accepting the terms ensures that you understand and accept the rules and responsibilities of being a host on our platform, ensuring a safe and fair experience for all users',
  'OnboardingHostAgreementForm:Text:Title': 'Accept the Conditions to be a Host',
  'OnboardingCarInfoForm:Error:GetLocations': 'It looks like there was a problem getting the locations',
  'OnboardingCarInfoForm:Error:Submit': 'It looks like there was a problem submiting the car info',
  'OnboardingCarInfoForm:Field:Generation': 'Generation',
  'OnboardingCarInfoForm:Field:Make': 'Make',
  'OnboardingCarInfoForm:Field:Mileage': 'Mileage',
  'OnboardingCarInfoForm:Field:Mileage:Short': 'Short Mileage',
  'OnboardingCarInfoForm:Field:Mileage:Medium': 'About 50K (mi)',
  'OnboardingCarInfoForm:Field:Mileage:Large': 'Near or Past 100K (mi)',
  'OnboardingCarInfoForm:Field:Model': 'Model',
  'OnboardingCarInfoForm:Field:Trim': 'Trim',
  'OnboardingCarInfoForm:Field:Year': 'Year',
  'OnboardingCarInfoForm:Note:Car': 'Manufacturer, model and year fields help users easily identify and compare their vehicle. Make sure you enter the correct information to build trust and attract more rentals',
  'OnboardingCarInfoForm:Note:CarTrim': 'The generation and trim fields help better detail the features of your vehicle. If you\'re not sure, you can skip them; They are not mandatory',
  'OnboardingCarInfoForm:Text:Description': 'We need you to provide the essential details of your vehicle',
  'OnboardingCarInfoForm:Text:Title': 'Basic Car Information',
  'OnboardingCarLocationForm:Field:Location': 'Location',
  'OnboardingCarLocationForm:Field:Place': 'Place',
  'OnboardingCarLocationForm:Field:VinNumber': 'VIN Number',
  'OnboardingCarLocationForm:Note:Location': 'The location of the vehicle indicates where users will be able to pick it up. Please make sure the address is accurate to avoid confusion during delivery',
  'OnboardingCarLocationForm:Note:Place': 'We recommend that you enter a secure public address, such as a gas station or shopping center, instead of your home address to protect your privacy',
  'OnboardingCarLocationForm:Note:VinNumber': 'The VIN (Vehicle Identification Number) is a unique code that identifies your vehicle. It helps us verify its authenticity and comply with the platform\'s security requirements',
  'OnboardingCarLocationForm:Text:Description': 'Provide the address where your vehicle is located so that users know where to pick it up when renting it',
  'OnboardingCarLocationForm:Text:Title': 'Enter Vehicle Location',
  'OnboardingCarPhotosForm:Button:Upload': 'Upload Photos',
  'OnboardingCarPhotosForm:Error:Submit': 'It looks like there was a problem submiting the car photos',
  'OnboardingCarPhotosForm:Error:ValidateCarPhotos': 'You must upload between 1 and 8 photos of your car',
  'OnboardingCarPhotosForm:Field:Photos': 'Car Photos',
  'OnboardingCarPhotosForm:Text:Description': 'Clear, detailed photos of your car help users view the vehicle before booking, building trust and increasing rental possibilities',
  'OnboardingCarPhotosForm:Text:Extensions': 'PNG, JPG, GIF up to 10MB',
  'OnboardingCarPhotosForm:Text:Title': 'Upload Photos of your Car',
  'OnboardingPaymentSetupForm:Button:ConnectStripe': 'Connect Stripe Account',
  'OnboardingPaymentSetupForm:Error:ConnectStripe': 'It seems that there has been a problem connecting to Stripe',
  'OnboardingPaymentSetupForm:Text:Description': 'Connecting your Stripe account allows us to send you payments securely and efficiently every time you rent your vehicle.',
  'OnboardingPaymentSetupForm:Text:Title': 'Connect your Stripe Account',
  'OnboardingProfilePhoneForm:Button:UpdatePhone': 'Update Phone',
  'OnboardingProfilePhoneForm:Button:VerifyCode': 'Verify Code',
  'OnboardingProfilePhoneForm:Error:UpdatePhone': 'It looks like there was a problem submiting the profile phone',
  'OnboardingProfilePhoneForm:Error:VerifyCode': 'It looks like there was a problem verifying the phone code',
  'OnboardingProfilePhoneForm:Field:PhoneCode': 'Country Code',
  'OnboardingProfilePhoneForm:Field:PhoneCode:CA': '+1 (Canada)',
  'OnboardingProfilePhoneForm:Field:PhoneCode:MX': '+52 (Mexico)',
  'OnboardingProfilePhoneForm:Field:PhoneCode:US': '+1 (United States)',
  'OnboardingProfilePhoneForm:Field:PhoneNumber': 'Phone Number',
  'OnboardingProfilePhoneForm:Field:VerificationCode': 'Verification Code',
  'OnboardingProfilePhoneForm:Note:Verification': 'To verify your phone number, you will receive an SMS with a code. If your number has already been verified previously, you can continue without problems',
  'OnboardingProfilePhoneForm:Text:Description': 'Verifying your phone number allows us to contact you easily and ensures smooth communication',
  'OnboardingProfilePhoneForm:Text:Title': 'Update and Verify your Phone Number',
  'OnboardingProfilePictureForm:Error:Submit': 'It looks like there was a problem submiting the picture',
  'OnboardingProfilePictureForm:Field:Picture': 'Profile Picture',
  'OnboardingProfilePictureForm:Note:Picture': 'The profile picture can be a personal image or a logo. This will be the image that users will see to identify you on the platform',
  'OnboardingProfilePictureForm:Text:Description': 'Your profile picture helps build trust with users and provides a more personal and secure experience on our platform',
  'OnboardingProfilePictureForm:Text:Title': 'Upload your Profile Picture',
};

export const listYourCarEs = {
  ALL_WHEEL_DRIVE: 'Tracción total',
  ANDROID_AUTO: 'Android Auto',
  APPLE_CAR_PLAY: 'Apple Car Play',
  AUX_INPUT: 'Entrada auxiliar',
  BACKUP_CAMERA: 'Cámara de respaldo',
  BIKE_RACK: 'Aparcabicicletas',
  BLIND_SPOT_WARNING: 'Advertencia de punto ciego',
  BLUETOOTH: 'Bluetooth',
  CHILD_SEAT: 'Asiento para niños',
  CONVERTIBLE: 'Convertible',
  GPS: 'GPS',
  HEATED_SEATS: 'Asientos con calefacción',
  KEYLESS_ENTRY: 'Entrada sin llave',
  PET_FRIENDLY: 'Se admiten mascotas',
  SKI_RACK: 'Portaesquís',
  SNOW_TIRES_OR_CHAINS: 'Neumáticos o cadenas para nieve',
  SUNROOF: 'Techo solar',
  TOLL_PASS: 'Pase de peaje',
  USB_CHARGER: 'Cargador USB',
  USB_INPUT: 'Entrada USB',
  WHEELCHAIR_ACCESSIBLE: 'Accesible para sillas de ruedas',
  'ListYourCarPage:Text:Steps': 'Paso {step} de 10',
  'ListYourCarPage:Text:Title': 'Enlista Tu Automóvil',
  'Onboarding*:Button:Next': 'Siguiente Paso',
  'OnboardingCarAvailabilityForm:Error:Submit': 'Parece que hubo un problema subiendo la disponibilidad del auto',
  'OnboardingCarAvailabilityForm:Field:MaximumTripDuration': 'Duración Máxima del Viaje',
  'OnboardingCarAvailabilityForm:Field:MaximumTripDuration:2W': '2 semanas (recomendado)',
  'OnboardingCarAvailabilityForm:Field:MinimumTripDuration': 'Duración Mínima del Viaje',
  'OnboardingCarAvailabilityForm:Field:MinimumTripDuration:1D': '1 día (recomendado)',
  'OnboardingCarAvailabilityForm:Field:ShortNotice': 'Aviso a Corto Plazo',
  'OnboardingCarAvailabilityForm:Field:ShortNotice:1D': '1 día (recomendado)',
  'OnboardingCarAvailabilityForm:Text:Description': 'Configurar las preferencias de disponibilidad de tu coche nos ayuda a gestionar las reservas de manera eficiente y asegura que tu vehículo esté disponible para los usuarios en los horarios que más te convengan',
  'OnboardingCarAvailabilityForm:Text:MaximumTripDuration': 'Indica el límite de tiempo que el automóvil puede ser rentado para un viaje',
  'OnboardingCarAvailabilityForm:Text:MinimumTripDuration': 'Indica cuánto tiempo es lo mínimo que el automóvil puede ser rentado',
  'OnboardingCarAvailabilityForm:Text:ShortNotice': 'Indica cuánto tiempo necesitas para preparar el automóvil antes de cada viaje nuevo',
  'OnboardingCarAvailabilityForm:Text:Title': 'Establece la Disponibilidad de tu Coche',
  'OnboardingCarDetailsForm:Error:GetLocations': 'Parece que hubo un problema obteniendo las ubicaciones',
  'OnboardingCarDetailsForm:Error:Submit': 'Parece que hubo un problema subiendo los detalles del auto',
  'OnboardingCarDetailsForm:Field:Description': 'Descripción',
  'OnboardingCarDetailsForm:Field:LicensePlate': 'Placas',
  'OnboardingCarDetailsForm:Field:LicensePlateState': 'Estado de Expedición',
  'OnboardingCarDetailsForm:Field:Name': 'Nombre',
  'OnboardingCarDetailsForm:Note:CarDetails': 'El nombre y descripción de tu vehículo es lo primero que los usuarios verán. Hazlo lo más atractivo posible para captar su interés y mejorar tus posibilidades de renta',
  'OnboardingCarDetailsForm:Note:CarFeatures': 'Selecciona las características y amenidades con las que cuenta tu vehículo, como GPS o asientos con calefacción. Si no tiene alguna, puedes omitirla sin problema',
  'OnboardingCarDetailsForm:Note:CarPlates': 'Ingresa el número de placas y el estado de expedición de tu vehículo. Estos datos ayudan a verificar su autenticidad y cumplir con los requisitos legales de la plataforma',
  'OnboardingCarDetailsForm:Text:Description': 'Proporcionar las características de tu coche permite a los usuarios conocer mejor el vehículo que van a rentar, ayudándoles a tomar una decisión informada y mejorando su experiencia en la plataforma',
  'OnboardingCarDetailsForm:Text:Title': 'Describe las Características de tu Coche',
  'OnboardingCarDocumentsForm:Error:Submit': 'Parece que hubo un problema subiendo la licencia de conductor del auto',
  'OnboardingCarDocumentsForm:Error:ValidateCarDocuments': 'Subir de 1 a 8 archivos',
  'OnboardingCarDocumentsForm:Note:Documents': 'Sube imágenes o archivos PDF de la tarjeta de circulación y la licencia de conducir registrada con el auto. Asegúrate de que sean correctos, ya que se revisarán para cumplir con los requisitos de la plataforma',
  'OnboardingCarDocumentsForm:Text:Description': 'Para garantizar la seguridad y el cumplimiento, necesitamos verificar los documentos oficiales de tu vehículo antes de habilitarlo para renta en la plataforma',
  'OnboardingCarDocumentsForm:Text:UploadFileDrag': 'Arrastra y suelta tus archivos aquí',
  'OnboardingCarDocumentsForm:Text:UploadFileClick': 'O haga clic para explorar sus archivos',
  'OnboardingCarDocumentsForm:Text:Title': 'Sube los Documentos de tu Vehículo',
  'OnboardingCarGuidelinesForm:Field:Checkbox': 'Acuerdo de las Pautas',
  'OnboardingCarGuidelinesForm:Field:CheckboxLabel': 'Al marcar la casilla estás aceptando que conoces las pautas que tienes que cumplir con el auto',
  'OnboardingCarGuidelinesForm:Text:Guidelines': `
  <p>Para mantener la calidad y la confianza en nuestra plataforma, es necesario que aceptes los siguientes compromisos antes de cada renta:</p>
  <ul>
  <li>Limpieza del Coche: Asegúrate de que tu coche esté limpio tanto por dentro como por fuera.</li>
  <li>Tanque Lleno: Proporciona tu coche con el tanque de combustible lleno.</li>
  <li>Mantenimiento Regular: Realiza el mantenimiento necesario para garantizar que tu coche esté en buenas condiciones.</li>
  <li>Exclusividad de la Plataforma: No enlistes tu coche en otra plataforma de renta.</li>
  </ul>
  <p>Estas medidas aseguran una experiencia positiva para los usuarios y mantienen altos estándares en nuestra comunidad.</p>
  `,
  'OnboardingCarGuidelinesForm:Text:Title': 'Pautas del Coche',
  'OnboardingHostAgreementForm:Error:CreateAgreement': 'Parece que ha ocurrido un problema enviando el acuerdo',
  'OnboardingHostAgreementForm:Field:Checkbox': 'Acuerdo de Anfitrión',
  'OnboardingHostAgreementForm:Field:CheckboxLabel': 'Al marcar la casilla estás aceptando que conoces los términos y condiciones que tienes que cumplir para convertirte en anfitrión',
  'OnboardingHostAgreementForm:Text:Description': 'Aceptar las condiciones asegura que comprendes y aceptas las reglas y responsabilidades de ser un anfitrión en nuestra plataforma, garantizando una experiencia segura y justa para todos los usuarios',
  'OnboardingHostAgreementForm:Text:Title': 'Acepta las Condiciones para ser Anfitrion',
  'OnboardingCarInfoForm:Error:Submit': 'Parece que hubo un problema subiendo la información del auto',
  'OnboardingCarInfoForm:Field:Generation': 'Generación',
  'OnboardingCarInfoForm:Field:Make': 'Fabricante',
  'OnboardingCarInfoForm:Field:Mileage': 'Kilometraje',
  'OnboardingCarInfoForm:Field:Mileage:Short': 'Poco Kilometraje',
  'OnboardingCarInfoForm:Field:Mileage:Medium': 'Aproximadamente 50K (Km)',
  'OnboardingCarInfoForm:Field:Mileage:Large': 'Cerca o más allá de 100K (Km)',
  'OnboardingCarInfoForm:Field:Model': 'Modelo',
  'OnboardingCarInfoForm:Field:Trim': 'Acabado',
  'OnboardingCarInfoForm:Field:Year': 'Año',
  'OnboardingCarInfoForm:Note:Car': 'Los campos de fabricante, modelo y año ayudan a los usuarios a identificar y comparar su vehículo fácilmente. Asegúrese de ingresar la información correcta para generar confianza y atraer más rentas',
  'OnboardingCarInfoForm:Note:CarTrim': 'Los campos de generación y acabado ayudan a detallar mejor las características de tu vehículo. Si no estás seguro, puedes omitirlos; no son obligatorios',
  'OnboardingCarInfoForm:Text:Description': 'Necesitamos que proporciones los detalles esenciales de tu vehículo',
  'OnboardingCarInfoForm:Text:Title': 'Información Básica del Vehículo',
  'OnboardingCarLocationForm:Field:Location': 'Ubicación',
  'OnboardingCarLocationForm:Field:Place': 'Lugar',
  'OnboardingCarLocationForm:Field:VinNumber': 'Número de Serie',
  'OnboardingCarLocationForm:Note:Location': 'La ubicación del vehículo indica dónde los usuarios podrán recogerlo. Asegúrate de que la dirección sea precisa para evitar confusiones durante la entrega',
  'OnboardingCarLocationForm:Note:Place': 'Te recomendamos ingresar una dirección pública segura, como una estación de servicio o centro comercial, en lugar de tu domicilio particular para proteger tu privacidad',
  'OnboardingCarLocationForm:Note:VinNumber': 'El NIV (Número de Identificación Vehicular) es un código único que identifica tu vehículo. Nos ayuda a verificar su autenticidad y cumplir con los requisitos de seguridad de la plataforma',
  'OnboardingCarLocationForm:Text:Description': 'Proporciona la dirección donde se encuentra tu vehículo para que los usuarios sepan dónde recogerlo al rentarlo',
  'OnboardingCarLocationForm:Text:Title': 'Ingresar Ubicación del Vehículo',
  'OnboardingCarPhotosForm:Button:Upload': 'Subir Fotos',
  'OnboardingCarPhotosForm:Error:Submit': 'Parece que hubo un problema subiendo las fotos del auto',
  'OnboardingCarPhotosForm:Error:ValidateCarPhotos': 'Debes subir entre 1 y 8 fotos de tu coche',
  'OnboardingCarPhotosForm:Field:Photos': 'Fotos del Automóvil',
  'OnboardingCarPhotosForm:Text:Description': 'Las fotografías claras y detalladas de su automóvil ayudan a los usuarios a ver el vehículo antes de reservar, generando confianza y aumentando las posibilidades de alquiler',
  'OnboardingCarPhotosForm:Text:Extensions': 'PNG, JPG, GIF hasta 10MB',
  'OnboardingCarPhotosForm:Text:Title': 'Sube Fotografías de tu Coche',
  'OnboardingPaymentSetupForm:Button:ConnectStripe': 'Conectar Cuenta de Stripe',
  'OnboardingPaymentSetupForm:Error:ConnectStripe': 'Parece que ha ocurrido un problema conectando  con Stripe',
  'OnboardingPaymentSetupForm:Text:Description': 'Conectar tu cuenta de Stripe nos permite enviarte los pagos de manera segura y eficiente cada vez que rentas tu vehículo.',
  'OnboardingPaymentSetupForm:Text:Title': 'Conecta tu Cuenta de Stripe',
  'OnboardingProfilePhoneForm:Button:UpdatePhone': 'Actualizar Teléfono',
  'OnboardingProfilePhoneForm:Button:VerifyCode': 'Verificar Código',
  'OnboardingProfilePhoneForm:Error:UpdatePhone': 'Parece que hubo un problema subiendo el número de teléfono',
  'OnboardingProfilePhoneForm:Error:VerifyCode': 'Parece que hubo un problema verificando el código',
  'OnboardingProfilePhoneForm:Field:PhoneCode': 'Código de País',
  'OnboardingProfilePhoneForm:Field:PhoneCode:CA': '+1 (Canadá)',
  'OnboardingProfilePhoneForm:Field:PhoneCode:MX': '+52 (México)',
  'OnboardingProfilePhoneForm:Field:PhoneCode:US': '+1 (Estados Unidos)',
  'OnboardingProfilePhoneForm:Field:PhoneNumber': 'Número de Teléfono',
  'OnboardingProfilePhoneForm:Field:VerificationCode': 'Código de Verificación',
  'OnboardingProfilePhoneForm:Note:Verification': 'Para verificar tu número de teléfono, recibirás un SMS con un código. Si tu número ya fue verificado previamente, puedes continuar sin problemas',
  'OnboardingProfilePhoneForm:Text:Description': 'Verificar tu número de teléfono nos permite contactarte fácilmente y asegura una comunicación fluida',
  'OnboardingProfilePhoneForm:Text:Title': 'Actualiza y Verifica tu Número de Teléfono',
  'OnboardingProfilePictureForm:Error:Submit': 'Parece que hubo un problema subiendo la foto de perfil',
  'OnboardingProfilePictureForm:Field:Picture': 'Foto de Perfil',
  'OnboardingProfilePictureForm:Note:Picture': 'La foto de perfil puede ser una imagen personal o un logo. Esta será la imagen que los usuarios verán para identificarte en la plataforma',
  'OnboardingProfilePictureForm:Text:Description': 'Tu imagen de perfil ayuda a generar confianza entre los usuarios y proporciona una experiencia más personal y segura en nuestra plataforma',
  'OnboardingProfilePictureForm:Text:Title': 'Sube tu Imagen de Perfil',
};
