/**
 * Do not edit directly
 * Generated on Tue, 29 Oct 2024 21:19:47 GMT
 */

export const BreakpointMobile = "320";
export const BreakpointMobileXl = "480";
export const BreakpointTablet = "481";
export const BreakpointTabletXl = "768";
export const BreakpointLaptop = "769";
export const BreakpointLaptopXl = "1024";
export const BreakpointDesktop = "1025";
export const BreakpointDesktopXl = "1200";
export const BreakpointTv = "1201";
export const ColorDarkBg100 = "#141218";
export const ColorDarkBg80 = "#1d1b20";
export const ColorDarkBg60 = "#2b2930";
export const ColorDarkBg40 = "#49454f";
export const ColorDarkBg20 = "#e6e0e9";
export const ColorDarkBlack100 = "#141218";
export const ColorDarkBlack80 = "#1d1b20";
export const ColorDarkBlack60 = "#2b2930";
export const ColorDarkContrast100 = "#fef7ff";
export const ColorDarkContrast80 = "#e6e0e9";
export const ColorDarkContrast60 = "#cac4d0";
export const ColorDarkContrast40 = "#49454f";
export const ColorDarkContrast20 = "#322f35";
export const ColorDarkError = "#e70d4f";
export const ColorDarkInfo = "#339af0";
export const ColorDarkPrimary = "#e96633";
export const ColorDarkSecondary = "#e8e54a";
export const ColorDarkSuccess = "#14a38c";
export const ColorDarkWarning = "#edb464";
export const ColorDarkWhite100 = "#fef7ff";
export const ColorDarkWhite80 = "#e6e0e9";
export const ColorDarkWhite60 = "#cac4d0";
export const ColorLightBg100 = "#fef7ff";
export const ColorLightBg80 = "#e6e0e9";
export const ColorLightBg60 = "#cac4d0";
export const ColorLightBg40 = "#49454f";
export const ColorLightBg20 = "#322f35";
export const ColorLightBlack100 = "#141218";
export const ColorLightBlack80 = "#1d1b20";
export const ColorLightBlack60 = "#2b2930";
export const ColorLightContrast100 = "#141218";
export const ColorLightContrast80 = "#1d1b20";
export const ColorLightContrast60 = "#2b2930";
export const ColorLightContrast40 = "#49454f";
export const ColorLightContrast20 = "#e6e0e9";
export const ColorLightError = "#e70d4f";
export const ColorLightInfo = "#339af0";
export const ColorLightPrimary = "#e96633";
export const ColorLightSecondary = "#256e95";
export const ColorLightSuccess = "#14a38c";
export const ColorLightWarning = "#edb464";
export const ColorLightWhite100 = "#fef7ff";
export const ColorLightWhite80 = "#e6e0e9";
export const ColorLightWhite60 = "#cac4d0";
