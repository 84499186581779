import { hostEs } from './host';
import { listYourCarEs } from './list-your-car';

const esMessages = {
  ...hostEs,
  ...listYourCarEs,
  'BusinessEmptyState:Text:Description': 'Consulta tus ingresos y gestiona tus ganancias obtenidas por la renta de tus vehículos en la plataforma',
  'BusinessEmptyState:Text:Heading': 'Resumen de Negocio',
  'CarListingEmptyState:Text:Description': 'Aquí puedes ver y gestionar todos los coches que has enlistado en la plataforma',
  'CarListingEmptyState:Text:Heading': 'Mis Carros Listados',
  'InboxEmptyState:Text:Description': 'Revisa y responde a los mensajes recibidos de los usuarios interesados en tus vehículos listados en la plataforma',
  'InboxEmptyState:Text:Heading': 'Bandeja de Entrada',
  'TripEmptyState:Text:Description': 'Consulta y gestiona todos los viajes realizados con tus vehículos listados en la plataforma',
  'TripEmptyState:Text:Heading': 'Historial de Viajes',
  'Calendar:Button:NextMonth': 'Siguiente Mes',
  'Calendar:Button:PreviousMonth': 'Mes Anterior',
  'Calendar:Day:Monday': 'L',
  'Calendar:Day:Tuesday': 'M',
  'Calendar:Day:Wednesday': 'M',
  'Calendar:Day:Thursday': 'J',
  'Calendar:Day:Friday': 'V',
  'Calendar:Day:Saturday': 'S',
  'Calendar:Day:Sunday': 'D',
  'Calendar:Text:From': 'Desde',
  'Calendar:Text:To': 'Hasta',
  'CarDeliveryLocationItem:Button:Delete': 'Borrar',
  'CarExtraItem:Button:Delete': 'Borrar',
  'Form:Error:MaxLength': '{name} debe tener una longitud máxima de {length} caracteres',
  'Form:Error:Required': '{name} es requerido',
  'GuestHeader:Button:Menu': 'Menú',
  'GuestHeader:Link:Host': 'Cambiar A Anfitrión',
  'GuestHeader:Link:Onboarding': 'Enlista Tu Vehículo',
  'Homepage:Alt:Logo': 'Logo de Sunix',
  'Homepage:Button:BecomeHost': 'Convertirse en anfitrión',
  'Homepage:Button:Login': 'Iniciar Sesión en Sunix',
  'Homepage:Description:ComingSoon': 'Rentar un auto nunca fue tan fácil. Conectamos propietarios y arrendatarios para que puedas encontrar el vehículo perfecto, justo cuando lo necesitas. ¡Suscríbete para recibir actualizaciones y estar entre los primeros en usar nuestra plataforma!',
  'Homepage:Description:EnterSunix': 'Ingresa tus datos para comenzar a utilizar nuestra plataforma.',
  'Homepage:Heading:ComingSoon': '¡Próximamente! Descubre una nueva forma de movilidad con nuestra plataforma de alquiler de autos entre particulares.',
  'Homepage:Heading:EnterSunix': 'Acceder a la plataforma',
  'Homepage:Heading:Title': 'Bienvenido(a) a Sunix',
  'HostHeader:Button:Menu': 'Menú',
  'HostHeader:Link:Business': 'Negocios',
  'HostHeader:Link:Guest': 'Cambiar A Invitado',
  'HostHeader:Link:Inbox': 'Bandeja',
  'HostHeader:Link:Vehicles': 'Vehículos',
  'HostHeader:Link:Trips': 'Viajes',
  'Modal:Button:Close': 'Cerrar',
  'NotFoundPage:Alt:Error': 'Error No Encontrado',
  'NotFoundPage:Heading:Error': 'Error 404: Página no encontrada',
  'NotFoundPage:Description:Error': 'La página que estás buscando no está disponible. Por favor, verifica la URL e intenta nuevamente.',
  'PreferencesEmptyState:Text:Description': 'Aún no has configurado ninguna preferencia. Añade las condiciones y opciones personalizadas para la renta de tu vehículo',
  'PreferencesEmptyState:Text:Heading': 'Preferencias del Anfitrión',
  'ProfilePicture:Button:Change': 'Cambiar Imagen',
  'ProfilePicture:Button:Upload': 'Subir Imagen',
  'ServerErrorState:Text:Description': 'Ocurrió un problema. Por favor, intenta nuevamente o contacta al soporte si el problema persiste',
  'ServerErrorState:Text:Heading': 'Error Inesperado',
};

export default esMessages;
