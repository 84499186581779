export const hostEn = {
  'CalendarView:Button:AddNewUnavailabilityPeriod': 'Set New Unavailability Period',
  'CalendarView:Error:DeleteUnavailabilityPeriod': 'It seems that there has been a problem deleting the period',
  'CalendarView:Error:GetUnavailabilityPeriod': 'It seems that there has been a problem getting the calendar info from the server',
  'CalendarView:Text:NewUnavailabilityPeriodTitle': 'New Unavailability Period',
  'CalendarView:Text:UnavailablePeriodListDescription': 'Set a period during which your vehicle will not be available for rentals on the platform',
  'CarListingView:Text:Description': 'Here you can see and manage all the cars you have listed on the platform',
  'CarListingView:Text:Title': 'Car Listing',
  'ExtrasView:Button:AddNewExtra': 'Add New Extra',
  'ExtrasView:Error:DeleteExtra': 'It looks like there was a problem deleting the extra',
  'ExtrasView:Error:GetExtras': 'It looks like there was a problem getting the extras',
  'ExtrasView:Text:NewExtraTitle': 'Add New Extra',
  'ExtrasView:Text:Description': 'Offers additional options such as accessories or extra services that users can add when renting their vehicle',
  'ExtrasView:Text:Title': 'Extra Elements for Rent',
  'HostDeliveryLocationForm:Button:Submit': 'Submit',
  'HostDeliveryLocationForm:Error:Submit': 'It looks like there was a problem sending the delivery location',
  'HostDeliveryLocationForm:Field:CheckInMethod': 'Check In Method',
  'HostDeliveryLocationForm:Field:Instructions': 'Instructions',
  'HostDeliveryLocationForm:Field:Price': 'Price',
  'HostDeliveryLocationForm:Text:Description': 'Specify a new location where you can drop off your vehicle for rentals',
  'HostDetailsForm:Button:Submit': 'Submit',
  'HostDetailsForm:Error:Submit': 'It looks like there was a problem sending the details',
  'HostDetailsForm:Field:Description': 'Description',
  'HostDetailsForm:Field:LicensePlate': 'License Plate',
  'HostDetailsForm:Field:LicensePlateState': 'License Plate State',
  'HostDetailsForm:Field:Name': 'Name',
  'HostDetailsForm:Text:Description': 'Providing the characteristics of your car allows users to better understand the vehicle they are going to rent, helping them make an informed decision and improving their experience on the platform',
  'HostDetailsForm:Text:Title': 'Describe the Features of your Car',
  'HostExtraForm:Button:Submit': 'Submit',
  'HostExtraForm:Error:Submit': 'It looks like there was a problem sending the extra',
  'HostExtraForm:Field:Extra': 'Extra',
  'HostExtraForm:Field:ExtraQuantity': 'Quantity',
  'HostExtraForm:Field:Description': 'Description',
  'HostExtraForm:Field:ExtraPrice': 'Price',
  'HostExtraForm:Field:PricePeriod:PerDay': 'Per Day',
  'HostExtraForm:Field:PricePeriod:PerTrip': 'Per Trip',
  'HostGuestsInstructionsForm:Button:Submit': 'Submit',
  'HostGuestsInstructionsForm:Error:Submit': 'It looks like there was a problem sending the guests instructions',
  'HostGuestsInstructionsForm:Field:PickAndDropInstructions': 'Pick And Drop Instructions',
  'HostGuestsInstructionsForm:Field:WelcomeMessage': 'Welcome Message',
  'HostGuestsInstructionsForm:Field:CarGuide': 'Car Guide',
  'HostGuestsInstructionsForm:Text:Description': 'Provide instructions that users must follow when renting and using your vehicle.',
  'HostGuestsInstructionsForm:Text:Title': 'Set the Guests Instructions of your Car',
  'HostIncludedDistanceForm:Button:Submit': 'Submit',
  'HostIncludedDistanceForm:Error:Submit': 'It looks like there was a problem submiting the car distance included',
  'HostIncludedDistanceForm:Field:IncludedDistance': 'Distance included',
  'HostIncludedDistanceForm:Field:IncludedDistance:Short': 'Less than 50 miles',
  'HostIncludedDistanceForm:Field:IncludedDistance:Medium': 'Less than 100 miles',
  'HostIncludedDistanceForm:Field:IncludedDistance:Large': 'Less than 200 miles',
  'HostIncludedDistanceForm:Text:Description': 'Define the range of kilometers or miles that will be included in each rental of your vehicle',
  'HostIncludedDistanceForm:Text:Title': 'Set the Included Distance of your Car',
  'HostPhotosForm:Button:Submit': 'Submit',
  'HostPhotosForm:Button:Upload': 'Upload Photos',
  'HostPhotosForm:Error:Submit': 'It looks like there was a problem submiting the car photos',
  'HostPhotosForm:Error:ValidateCarPhotos': 'You must upload between 1 and 8 photos of your car',
  'HostPhotosForm:Field:Photos': 'Car Photos',
  'HostPhotosForm:Text:Description': 'Clear, detailed photos of your car help users view the vehicle before booking, building trust and increasing rental possibilities',
  'HostPhotosForm:Text:Extensions': 'PNG, JPG, GIF up to 10MB',
  'HostPhotosForm:Text:Title': 'Upload Photos of your Car',
  'HostPriceAndDiscountsForm:Button:Submit': 'Submit',
  'HostPriceAndDiscountsForm:Error:Submit': 'It looks like there was a problem sending the price and discounts',
  'HostPriceAndDiscountsForm:Field:Price': 'Price',
  'HostPriceAndDiscountsForm:Field:Discount3PlusDays': '3+ Day Discount',
  'HostPriceAndDiscountsForm:Field:Discount7PlusDays': '7+ Day Discount',
  'HostPriceAndDiscountsForm:Field:Discount30PlusDays': '30+ Day Discount',
  'HostPriceAndDiscountsForm:Text:Description': 'Set the daily price of your vehicle and apply personalized discounts to attract more rentals on the platform',
  'HostPriceAndDiscountsForm:Text:Heading': 'Set Prices and Discounts',
  'HostTripPreferencesForm:Button:Submit': 'Submit',
  'HostTripPreferencesForm:Error:Submit': 'It looks like there was a problem submiting the car trip preferences',
  'HostTripPreferencesForm:Field:MaximumTripDuration': 'Maximum Trip Duration',
  'HostTripPreferencesForm:Field:MaximumTripDuration:2W': '2 weeks (recommended)',
  'HostTripPreferencesForm:Field:MinimumTripDuration': 'Minimum Trip Duration',
  'HostTripPreferencesForm:Field:MinimumTripDuration:1D': '1 Day (recommended)',
  'HostTripPreferencesForm:Field:ShortNotice': 'Short Notice',
  'HostTripPreferencesForm:Field:ShortNotice:1D': '1 Day (recommended)',
  'HostTripPreferencesForm:Text:Description': 'Setting your car\'s availability preferences helps us manage reservations efficiently and ensures that your vehicle is available to users at times that best suit you',
  'HostTripPreferencesForm:Text:Title': 'Set the Availability of your Car',
  'HostUnavailabilityPeriodForm:Error:CreateUnavailabilityPeriod': 'It seems that there has been a problem submiting your unavailability period',
  'HostUnavailabilityPeriodForm:Button:Submit': 'Submit',
  'HostUnavailabilityPeriodForm:Field:EndDate': 'End Date',
  'HostUnavailabilityPeriodForm:Field:StartDate': 'Start Date',
  'HostPage:NavLink:Calendar': 'Calendar',
  'HostPage:NavLink:Details': 'Details',
  'HostPage:NavLink:Extras': 'Extras',
  'HostPage:NavLink:GuestsInstructions': 'Guests Instructions',
  'HostPage:NavLink:LocationsAndDelivery': 'Locations And Delivery',
  'HostPage:NavLink:Photos': 'Photos',
  'HostPage:NavLink:PriceAndDiscounts': 'Price And Discounts',
  'HostPage:NavLink:DistanceIncluded': 'Distance Included',
  'HostPage:NavLink:TripPreferences': 'Trip Preferences',
  'LocationAndDeliveryView:Text:Description': 'Select and manage locations where you can drop off your vehicle to your rental users',
  'LocationAndDeliveryView:Text:Heading': 'Setting Up Drop-Off Locations',
  'HostPreferenceEmptyState:Text:Description': '',
  'HostPreferenceEmptyState:Text:Heading': '',
  'SearchPage:Description:Search': 'Explore and search for vehicles available for rent based on your needs and preferences.',
  'SearchPage:Heading:Search': 'Search Available Vehicles',
};

export const hostEs = {
  'CalendarView:Button:AddNewUnavailabilityPeriod': 'Establece un Nuevo Periodo de Inactividad',
  'CalendarView:Error:DeleteUnavailabilityPeriod': 'Parece que hubo un problema borrando el periodo de inactividad',
  'CalendarView:Error:GetUnavailabilityPeriod': 'Parece que hubo un problema obteniendo la información del calendario desde el servidor',
  'CalendarView:Text:NewUnavailabilityPeriodTitle': 'Nuevo Periodo de Inactividad',
  'CalendarView:Text:UnavailablePeriodListDescription': 'Configure un período durante el cual su vehículo no estará disponible para alquileres en la plataforma',
  'CarListingView:Text:Description': 'Aquí puedes ver y gestionar todos los coches que has enlistado en la plataforma',
  'CarListingView:Text:Title': 'Mis Carros Listados',
  'ExtrasView:Button:AddNewExtra': 'Añadir Nuevo Extra',
  'ExtrasView:Error:DeleteExtra': 'Parece que hubo un problema borrando el extra',
  'ExtrasView:Error:GetExtras': 'Parece que hubo un problema obteniendo extras',
  'ExtrasView:Text:NewExtraTitle': 'Añade Un Nuevo Extra',
  'ExtrasView:Text:Description': 'Ofrece opciones adicionales como accesorios o servicios extra que los usuarios pueden añadir al rentar tu vehículo',
  'ExtrasView:Text:Title': 'Elementos Extras a la Renta',
  'HostDeliveryLocationForm:Button:Submit': 'Enviar',
  'HostDeliveryLocationForm:Error:Submit': 'Parece que hubo un problema subiendo la ubicación de entrega',
  'HostDeliveryLocationForm:Field:CheckInMethod': 'Método de check-in',
  'HostDeliveryLocationForm:Field:Instructions': 'Instrucciones',
  'HostDeliveryLocationForm:Field:Price': 'Precio',
  'HostDeliveryLocationForm:Text:Description': 'Especifica una nueva ubicación donde puedes entregar tu vehículo para las rentas',
  'HostDetailsForm:Button:Submit': 'Enviar',
  'HostDetailsForm:Error:Submit': 'Parece que hubo un problema subiendo los detalles del auto',
  'HostDetailsForm:Field:Description': 'Descripción',
  'HostDetailsForm:Field:LicensePlate': 'Placas',
  'HostDetailsForm:Field:LicensePlateState': 'Estado de Expedición',
  'HostDetailsForm:Field:Name': 'Nombre',
  'HostDetailsForm:Text:Description': 'Proporcionar las características de tu coche permite a los usuarios conocer mejor el vehículo que van a rentar, ayudándoles a tomar una decisión informada y mejorando su experiencia en la plataforma',
  'HostDetailsForm:Text:Title': 'Describe las Características de tu Coche',
  'HostExtraForm:Button:Submit': 'Enviar',
  'HostExtraForm:Error:Submit': 'Parece que hubo un problema subiendo el extra',
  'HostExtraForm:Field:Extra': 'Extra',
  'HostExtraForm:Field:ExtraQuantity': 'Cantidad',
  'HostExtraForm:Field:Description': 'Descripción',
  'HostExtraForm:Field:ExtraPrice': 'Precio',
  'HostExtraForm:Field:PricePeriod:PerDay': 'Por Día',
  'HostExtraForm:Field:PricePeriod:PerTrip': 'Por Viaje',
  'HostGuestsInstructionsForm:Button:Submit': 'Enviar',
  'HostGuestsInstructionsForm:Error:Submit': 'Parece que hubo un problema subiendo las instrucciones para invitados',
  'HostGuestsInstructionsForm:Field:PickAndDropInstructions': 'Instrucciones de recogida y entrega.',
  'HostGuestsInstructionsForm:Field:WelcomeMessage': 'Mensaje de Bienvenida',
  'HostGuestsInstructionsForm:Field:CarGuide': 'Guía del Coche',
  'HostGuestsInstructionsForm:Text:Description': 'Proporciona las instrucciones que los usuarios deben seguir al rentar y utilizar tu vehículo',
  'HostGuestsInstructionsForm:Text:Title': 'Establece las Instrucciones para Invitados de tu Coche',
  'HostIncludedDistanceForm:Button:Submit': 'Enviar',
  'HostIncludedDistanceForm:Error:Submit': 'Parece que hubo un problema subiendo la distancia incluída',
  'HostIncludedDistanceForm:Field:IncludedDistance': 'Distancia Incluída',
  'HostIncludedDistanceForm:Field:IncludedDistance:Short': 'Menos de 50 Km',
  'HostIncludedDistanceForm:Field:IncludedDistance:Medium': 'Menos de 100 Km',
  'HostIncludedDistanceForm:Field:IncludedDistance:Large': 'Menos de 200 Km',
  'HostIncludedDistanceForm:Text:Description': 'Define el rango de kilómetros o millas que estarán incluidos en cada renta de tu vehículo',
  'HostIncludedDistanceForm:Text:Title': 'Establece la Distancia Incluida de tu Coche',
  'HostPhotosForm:Button:Submit': 'Enviar',
  'HostPhotosForm:Button:Upload': 'Subir Fotos',
  'HostPhotosForm:Error:Submit': 'Parece que hubo un problema subiendo las fotos del auto',
  'HostPhotosForm:Error:ValidateCarPhotos': 'Debes subir entre 1 y 8 fotos de tu coche',
  'HostPhotosForm:Field:Photos': 'Fotos del Automóvil',
  'HostPhotosForm:Text:Description': 'Las fotografías claras y detalladas de su automóvil ayudan a los usuarios a ver el vehículo antes de reservar, generando confianza y aumentando las posibilidades de alquiler',
  'HostPhotosForm:Text:Extensions': 'PNG, JPG, GIF hasta 10MB',
  'HostPhotosForm:Text:Title': 'Sube Fotografías de tu Coche',
  'HostPriceAndDiscountsForm:Button:Submit': 'Enviar',
  'HostPriceAndDiscountsForm:Error:Submit': 'Parece que hubo un problema subiendo los precios y descuentos',
  'HostPriceAndDiscountsForm:Field:Price': 'Precio',
  'HostPriceAndDiscountsForm:Field:Discount3PlusDays': 'Descuento por 3 días o más',
  'HostPriceAndDiscountsForm:Field:Discount7PlusDays': 'Descuento por 7 días o más',
  'HostPriceAndDiscountsForm:Field:Discount30PlusDays': 'Descuento por 30 días o más',
  'HostPriceAndDiscountsForm:Text:Description': 'Establece el precio diario de tu vehículo y aplica descuentos personalizados para atraer más alquileres en la plataforma',
  'HostPriceAndDiscountsForm:Text:Heading': 'Establecer precios y descuentos',
  'HostTripPreferencesForm:Button:Submit': 'Enviar',
  'HostTripPreferencesForm:Error:Submit': 'Parece que hubo un problema subiendo la disponibilidad del auto',
  'HostTripPreferencesForm:Field:MaximumTripDuration': 'Duración Máxima del Viaje',
  'HostTripPreferencesForm:Field:MaximumTripDuration:2W': '2 semanas (recomendado)',
  'HostTripPreferencesForm:Field:MinimumTripDuration': 'Duración Mínima del Viaje',
  'HostTripPreferencesForm:Field:MinimumTripDuration:1D': '1 día (recomendado)',
  'HostTripPreferencesForm:Field:ShortNotice': 'Aviso a Corto Plazo',
  'HostTripPreferencesForm:Field:ShortNotice:1D': '1 día (recomendado)',
  'HostTripPreferencesForm:Text:Description': 'Configurar las preferencias de disponibilidad de tu coche nos ayuda a gestionar las reservas de manera eficiente y asegura que tu vehículo esté disponible para los usuarios en los horarios que más te convengan',
  'HostTripPreferencesForm:Text:Title': 'Establece la Disponibilidad de tu Coche',
  'HostUnavailabilityPeriodForm:Button:Submit': 'Subir',
  'HostUnavailabilityPeriodForm:Error:CreateUnavailabilityPeriod': 'Parece que hubo un problema subiendo el periodo de inactividad',
  'HostUnavailabilityPeriodForm:Field:EndDate': 'Fecha de Termino',
  'HostUnavailabilityPeriodForm:Field:StartDate': 'Fecha de Inicio',
  'HostPage:NavLink:Calendar': 'Calendario',
  'HostPage:NavLink:Details': 'Detalles',
  'HostPage:NavLink:Extras': 'Extras',
  'HostPage:NavLink:GuestsInstructions': 'Instrucciones para los invitados',
  'HostPage:NavLink:LocationsAndDelivery': 'Ubicaciones y entrega',
  'HostPage:NavLink:Photos': 'Fotos',
  'HostPage:NavLink:PriceAndDiscounts': 'Precio y descuentos',
  'HostPage:NavLink:DistanceIncluded': 'Distancia incluida',
  'HostPage:NavLink:TripPreferences': 'Preferencias de viaje',
  'LocationAndDeliveryView:Text:Description': 'Selecciona y gestiona las ubicaciones donde puedes entregar tu vehículo a los usuarios que lo renten',
  'LocationAndDeliveryView:Text:Heading': 'Ubicaciones de Entrega',
  'SearchPage:Description:Search': 'Explora y busca vehículos disponibles para rentar según tus necesidades y preferencias',
  'SearchPage:Heading:Search': 'Buscar Vehículos Disponibles',
};
