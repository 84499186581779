import { hostEn } from './host';
import { listYourCarEn } from './list-your-car';

const enMessages = {
  ...hostEn,
  ...listYourCarEn,
  'BusinessEmptyState:Text:Description': 'Check your income and manage your profits obtained from renting your vehicles on the platform',
  'BusinessEmptyState:Text:Heading': 'Business Summary',
  'CarListingEmptyState:Text:Description': 'Here you can see and manage all the cars you have listed on the platform',
  'CarListingEmptyState:Text:Heading': 'Car Listing',
  'InboxEmptyState:Text:Description': 'Review and respond to messages received from users interested in your vehicles listed on the platform',
  'InboxEmptyState:Text:Heading': 'Inbox',
  'TripEmptyState:Text:Description': 'Consult and manage all the trips made with your vehicles listed on the platform',
  'TripEmptyState:Text:Heading': 'Trip History',
  'Calendar:Button:NextMonth': 'Next Month',
  'Calendar:Button:PreviousMonth': 'Previous Month',
  'Calendar:Day:Monday': 'M',
  'Calendar:Day:Tuesday': 'T',
  'Calendar:Day:Wednesday': 'W',
  'Calendar:Day:Thursday': 'T',
  'Calendar:Day:Friday': 'F',
  'Calendar:Day:Saturday': 'S',
  'Calendar:Day:Sunday': 'S',
  'Calendar:Text:From': 'From',
  'Calendar:Text:To': 'To',
  'CarDeliveryLocationItem:Button:Delete': 'Delete',
  'CarExtraItem:Button:Delete': 'Delete',
  'Form:Error:MaxLength': '{name} must be {length} characters max length',
  'Form:Error:Required': '{name} is required',
  'GuestHeader:Button:Menu': 'Menu',
  'GuestHeader:Link:Host': 'Switch To Host',
  'GuestHeader:Link:Onboarding': 'List Your Car',
  'Homepage:Alt:Logo': 'Sunix Logo',
  'Homepage:Button:BecomeHost': 'Become Host',
  'Homepage:Button:Login': 'Login to Sunix',
  'Homepage:Description:ComingSoon': 'Renting a car has never been so easy. We connect owners and lessees so you can find the perfect vehicle, right when you need it. Subscribe to receive updates and be among the first to use our platform!',
  'Homepage:Description:EnterSunix': 'Enter your information to start using our platform.',
  'Homepage:Heading:ComingSoon': 'Soon! Discover a new form of mobility with our car rental platform between individuals.',
  'Homepage:Heading:EnterSunix': 'Access the platform',
  'Homepage:Heading:Title': 'Welcome to Sunix',
  'HostHeader:Button:Menu': 'Menu',
  'HostHeader:Link:Business': 'Business',
  'HostHeader:Link:Guest': 'Switch To Guest',
  'HostHeader:Link:Inbox': 'Inbox',
  'HostHeader:Link:Vehicles': 'Vehicles',
  'HostHeader:Link:Trips': 'Trips',
  'Modal:Button:Close': 'Close',
  'NotFoundPage:Alt:Error': 'Not found error',
  'NotFoundPage:Heading:Error': 'Error 404: Page not found',
  'NotFoundPage:Description:Error': 'The page you\'re looking for is not available. Please check the URL and try again.',
  'ProfilePicture:Button:Change': 'Change Picture',
  'ProfilePicture:Button:Upload': 'Upload Picture',
  'PreferencesEmptyState:Text:Description': 'You haven\'t set any preferences yet. Add personalized conditions and options for your vehicle rental',
  'PreferencesEmptyState:Text:Heading': 'Host Preferences',
  'ServerErrorState:Text:Description': 'A problem occurred. Please try again or contact support if the problem persists',
  'ServerErrorState:Text:Heading': 'Unexpected Error',
};

export default enMessages;
