import PropTypes from 'prop-types';
import React from 'react';

const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            isLoading: false,
            userToken: action.token,
          };
        case 'SIGN_IN':
          return {
            ...prevState,
            isLoading: false,
            userToken: action.token,
          };
        case 'SIGN_OUT':
          return {
            ...prevState,
            isSignedOut: true,
            userToken: null,
            isLoading: false,
          };
        default:
          return prevState;
      }
    },
    {
      isLoading: false,
      isSignedOut: false,
      userToken: null,
    },
  );
  const authContext = React.useMemo(
    () => ({
      signIn: async (token) => {
        dispatch({ type: 'SIGN_IN', token });
      },
      restoreToken: (token) => dispatch({ type: 'RESTORE_TOKEN', token }),
      signOut: () => dispatch({ type: 'SIGN_OUT' }),
      ...state,
    }),
    [state],
  );
  return (
    <AuthContext.Provider value={authContext}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export {
  AuthContext,
  AuthProvider,
};
